import React from "react";
import Layout from "../../components/layout";
import SEO from "../../components/seo";
import Navigation from "../../components/navigation";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Alert from "react-bootstrap/Alert";
import ReactGA from "react-ga";
import { Link } from "gatsby";
import DonationFooter from "../../components/donation-footer";
import CodeSnippet from '../../components/code-snippet'

class View extends React.Component {
	constructor(props) {
		super(props);
	}

	componentDidMount() {
		ReactGA.initialize("UA-115464417-1");
		if (!(window.location.hostname === "localhost")) {
			ReactGA.pageview(window.location.pathname + window.location.search);
		}
    }

	render() {
		return (
			<CodeSnippet
			
			header={'Dijkstra\'s Algorithm | Code Tutorial'}
			subHeader={''}
			description={'Dijkstra\'s algorithm (or Dijkstra\'s Shortest Path First algorithm, SPF algorithm) is an algorithm for finding the shortest paths between nodes in a graph, which may represent, for example, road networks.'}
			keywords={[
				'dijkstra\'s algorithm',
				'dijkstra\'s algorithm code tutorial',
				'dijkstra\'s algorithm tutorial',
				'dijkstra\'s algorithm youtube',
			]}
			embeddedVideo={'LpJceTbzJFo'}
			embeddedCode={'https://gist.githubusercontent.com/graphoarty/3805c2ef52304309f6308e35341ee8af/raw/da2964a087b9722a75d625499c42051327516e91/da.java'}		

			></CodeSnippet>
		);
	}
}

export default View;
